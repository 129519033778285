import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/Home';
import About from './components/About';
import Article from './components/Article';
import LoginForm from './components/Login';
import Remark from './components/Remark';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [remarkData, setRemarkData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Initiate both fetch requests concurrently
        const [articlesResponse, remarkResponse] = await Promise.all([
          fetch('/articles?query=art:10%20and%20cmt:10', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
          fetch('/remarks?query=last:6%20and%20skipped:false', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
        ]);
  
        // Check if both responses are ok
        if (!articlesResponse.ok) {
          throw new Error('Failed to fetch articles');
        }
        if (!remarkResponse.ok) {
          throw new Error('Failed to fetch remarks');
        }
  
        // Parse the JSON data from both responses
        const articlesData = await articlesResponse.json();
        const remarksData = await remarkResponse.json();
  
        // Update the state with the fetched data
        setApiData(articlesData);
        setRemarkData(remarksData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // The empty array ensures this effect runs only once on mount

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={apiData ? <HomePage articleData={apiData} remarkData={remarkData} /> : 'Loading...'} />
          <Route path="/about" element={<About />} />
          {/* Add more routes as needed */}
          <Route path="/login" element={<LoginForm isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setLoggedInUser={setLoggedInUser}/>} />
          <Route path="/remark" element={<Remark loggedInUser={loggedInUser}/>} />
          {/* <Route path="/article/:id" component={Article} /> */}
          <Route path="/article/:id" element={<Article />} />
          {/* <Route path="/article" element={<Article />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
