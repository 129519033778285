import React, { useEffect, useState } from 'react';
import { Typography, Box, Link, Grid } from '@mui/material';
import { useStyles } from './Styles';
import { useParams } from 'react-router-dom';
import Layout from './Layout'; // Import Layout component
import { stripFontSizeStyles } from './Util';
import { changeFontSize } from './ChangeFontSize';
import { getLocaleDate, isSameDate } from './DateUtil';

function Article() {
  const classes = useStyles();
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(async () => {
    const storedArticle = localStorage.getItem('selectedArticle');
    if (storedArticle) {
      const parsedArticle = JSON.parse(storedArticle);
      if (parsedArticle.id.toString() === id) {

        const response = await fetch(`/article/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          parsedArticle.content = 'Failed to fetch article';
        } else {
          const data = await response.json();
          if (data && data.content) {
            try {
              //parsedArticle.content = atob(data.content);
              parsedArticle.content = stripFontSizeStyles(data.content);
            } catch (e) {
              console.error('Failed to decode content:', e);
              parsedArticle.content = 'Failed to decode article content';
            }
          } else {
            parsedArticle.content = 'Failed to fetch article';
          }
        }

        setArticle(parsedArticle);
      }
    }
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  console.log('Article page rendered');
  const publishedDate = article.published ? new Date(article.published) : null;
  const lastUpdateDate = article.last_update ? new Date(article.last_update) : null;

  return (
    <Layout>
      <Grid container id="grdMenu" className={classes.menuBand}>
        <Grid item xs={9}>
          <Box sx={{ textAlign: 'left', paddingLeft: '8px' }}>
            <Link href="/">Home</Link> | <Link href="/login">Login</Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.rightHref}>
            <Link component="button" onClick={() => changeFontSize(4)}>A</Link> |
            <Link component="button" onClick={() => changeFontSize(-4)} style={{ marginRight: 20 }}>a</Link>
          </Box>
        </Grid>
      </Grid>
      <hr className={classes.hrLine2} />
      <Grid container className={classes.main} id="grdMainBody" style={{ minWidth: '320px' }}>
        <div>
          <h1>{article.title}</h1>
          <Typography className={classes.authorSpan}>
            by {article.authors}<br />{getLocaleDate(publishedDate)}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
          {!isSameDate(publishedDate, lastUpdateDate) && (
          <Typography className={`${classes.authorSpan} ${classes.smallAuthorSpanFont}`}>
            <br />
            Last updated: {getLocaleDate(lastUpdateDate)}
          </Typography>
        )}
        </div>
      </Grid>
    </Layout>
  );
};

export default Article;