import { makeStyles } from '@mui/styles';

const paleBlueBackground = '#e6f3ff';
const darkBlueForeground = '#005cb3';
const darkerBlue = '#003380';
export const cadetBlue = '#618685';
export const lightGray = '#f0f0f5';
export const gray = '#808080';
export const paleGrayBackground = '#f2f2f2';

export const useStyles = makeStyles(() => ({
    headerTaglineBand: {
        flexGrow: 1,
        backgroundColor: paleBlueBackground,
        paddingLeft: '2px', // or any other value
        paddingRight: '24px', // or any other value
    },
    container: {
        maxWidth: 'none',
        paddingLeft: 0, // or any other value
        paddingRight: 0, // or any other value

        // commented otherwise scroll for the entire page does not come and 
        // we cannot see the footer
        //height: '100vh', /* Adjust based on your layout, ensures the container fits within the viewport height */
        //overflow: 'hidden', /* Prevents vertical scrolling on the entire container */
    },
    adBand: {
        maxWidth: 'none',
        backgroundColor: paleBlueBackground,
        paddingLeft: 0, // or any other value
        paddingRight: 0, // or any other value
    },
    header: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bold !important',
        fontSize: '200%',
        color: darkBlueForeground,
        border: '2px',
        padding: '2px',
        margin: '2px',
    },
    tagline: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bold !important',
        fontSize: 'larger',
        fontStyle: 'italic',
        color: 'black',
        border: '2px',
        padding: '2px',
        margin: '2px',
    },
    taglineDateTimestamp: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bold',
        fontSize: '95%',
        fontStyle: 'italic',
        color: 'black',
        border: '2px',
        padding: '2px',
        margin: '2px',
    },
    menuBand: {
        backgroundColor: paleGrayBackground,
        paddingLeft: 0, // or any other value
        paddingRight: 0, // or any other value
    },
    hrLine1: {
        borderWidth: '0px',
        height: '2px',
        color: darkerBlue,
        backgroundColor: darkerBlue,
        margin: '0px'
    },
    hrLine2: {
        borderWidth: '0px',
        height: '1px',
        color: cadetBlue,
        backgroundColor: cadetBlue,
        margin: '0px'
    },
    title: {
        flexGrow: 1,
    },
    articleContainer: {
        backgroundColor: paleGrayBackground,
        marginBottom: '2px',
        paddingBottom: '2px',
        borderBottom: '1px solid black',
    },
    articleTitle: {
        fontSize: '100%',
        marginBottom: '0',
        textAlign: 'left',
    },
    articleAuthorDate: {
        fontSize: '75%',
        fontStyle: 'italic',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px', // Add a bottom margin to create spacing
    },
    authorSpan: {
        fontWeight: 'normal',
        fontSize: '85%',
        fontStyle: 'italic',
       // marginRight: '10px',
    },
    smallAuthorSpanFont: {
        fontSize: '82%  !important', // Smaller font size
      },
    middleColPreview: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '100%',
        marginTop: '25px',
        marginBottom: '0',
        textAlign: 'justify',
        display: 'block',
    },
    invisible: {
        //visibility: 'hidden',
    },
    rightHref: {
        textAlign: 'right',
    },
    topalert: {
        textAlign: 'center',
    },
    main: {
        flexGrow: 1, // Takes up the remaining height
        //overflow: 'auto', // Adds a scrollbar if necessary
        minHeight: '82vh',
    },
    footer: {
        border: '2px',
        padding: '2px',
    },
    mainBoxBorder: {
        //border: '1px solid #ddd', // Faint outline
        padding: '8px',
        height: '100%', // Full height
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    flexItem: {
        flex: '1 1 auto',
        //border: '1px solid #ddd', // Faint outline
        borderBottom: '1px solid lightGray',           // cadetBlue
        padding: '6px',
        overflow: 'auto',
        fontStyle: 'italic'
    },
    commentary: {
        margin: '8px',
        padding: '8px',
        textAlign: 'left',
    },
    commentaryContainer: {
        //backgroundColor: paleGrayBackground,
        marginBottom: '2px',
        paddingBottom: '25px',
        borderBottom: '1px solid lightGray',
    },
    mainColumn: {
        height: 'calc(100vh - 20px)', // Adjust as needed
        overflowY: 'auto',
    },
    remarksHeading: {
        marginBlockStart: '0',
        marginBlockEnd: '0',
        fontStyle: 'normal',
    },
    remarkContainer: {
        backgroundColor: '#f0f0f0', // Pale light gray background
        borderRadius: '10px', // Rounded corners
        padding: '6px', // Padding inside the container
        margin: '7px 0', // Margin between containers
        border: '1px solid #ddd', // Optional: Faint outline
    },
    aboutText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'justify',
        padding: '10px',
        
    },
}));