// utils.js
export const stripFontSizeStyles = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const elements = doc.body.querySelectorAll('*');
  
    elements.forEach(element => {
      element.style.fontSize = ''; // Remove inline font-size styles
    });
  
    return doc.body.innerHTML;
  };