import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useStyles } from './Styles';
import { getCurrentDateTime } from './DateUtil';

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container id="grdHeader">
        <Grid item xs={5}>
          <Box className={classes.headerTaglineBand} sx={{ textAlign: 'left' }}>
            <Typography variant="h6" className={classes.header}>
              21 CENTURY POLITICS
            </Typography>
            <Typography variant="body1" className={classes.tagline}>
              What we cannot avoid
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.adBand}>
          <Box>
            {/* Sponsor Ad here */}
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.headerTaglineBand} sx={{ textAlign: 'right' }}>
          <Box className={classes.taglineDateTimestamp}>
            {getCurrentDateTime()}
          </Box>
        </Grid>
      </Grid>
      <hr className={classes.hrLine1} />
      {/* Main content */}
      {children}
      <hr />
      <Grid container className={classes.footer} id="grdFooter">
        {/* Footer content */}
        <Box>
          <Typography className={classes.footer}>
            &copy; {new Date().getFullYear()} NeoSemantix, Inc. All rights reserved.
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};

export default Layout;