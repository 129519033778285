import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { useStyles, cadetBlue, gray, paleGrayBackground } from './Styles';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { changeFontSize } from './ChangeFontSize';

// Function to format the JSON record
const formatRecord = (record) => {
    return (
        <div>
            <strong> ID:</strong> {record.id}<strong> User:</strong> {record.user_name}
            <p><strong></strong> {record.content}</p>
            <p><strong></strong> {record.reference_url.Valid ? <a href={record.reference_url.String}>{record.reference_url.String}</a> : 'N/A'}</p>
            <p><strong>Reference:</strong> {record.reference.Valid ? record.reference.String : 'N/A'}
                <br />
                <strong>When:</strong> {new Date(record.created_when / 1000000).toLocaleString()}
                <strong> Pinned:</strong> {record.pinned ? 'Yes' : 'No'}
                <strong> Skipped:</strong> {record.skipped ? 'Yes' : 'No'}
            </p>
        </div>
    );
};

const Remark = ({ loggedInUser }) => {
    const classes = useStyles();
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const [message, setMessage] = useState('');
    const [formValues, setFormValues] = useState({
        remark: '',
        reference: '',
        url: '',
        pinned: false,
        skipped: false,
        id: '',
    });
    const [displayValues, setDisplayValues] = useState({});
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const [remarkIdInput, setRemarkIdInput] = useState('');
    const [remarkQueryInput, setRemarkQueryInput] = useState('');
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
        }

        const connectEventSource = () => {
            const eventSource = new EventSource('/session-expiry');
            console.log('session expiry connection established');

            eventSource.onmessage = (event) => {
                console.log('Received event', event);
                if (event.data === 'sessionExpired') {
                    setSessionExpired(true); // Set the session expired flag
                    console.log('Session expired, closing EventSource connection and going to login.');
                    eventSource.close();
                    navigate('/login');
                } else if (event.data === 'keep-alive') {
                    console.log('Keep-alive message received');
                }
            };

            eventSource.onerror = () => {
                if (!sessionExpired) {
                    console.log('EventSource connection error, attempting to reconnect...');
                    eventSource.close();
                    setTimeout(connectEventSource, 5000); // Attempt to reconnect after 5 seconds
                } else {
                    console.log('Session expired, closing EventSource connection.');
                    eventSource.close();
                }
            };

            // Clean up the event source on component unmount
            return () => eventSource.close();
        };

        connectEventSource();
    }, [navigate]);

    const clearForm = () => {
        const emptyForm = {
            remark: '',
            reference: '',
            url: '',
            pinned: false,
            skipped: false,
            id: '',
        };
        setFormValues(emptyForm);
        setDisplayValues(emptyForm);
    };

    const resetFormValues = () => {
        setMessage('');
        clearForm();
        setRemarkIdInput('');
        setRemarkQueryInput('');
    };

    const handleSubmit1 = async (event) => {
        event.preventDefault();
        const id = event.target.elements.remark_id_input.value.trim();

        if (id === '') {
            setMessage('');
            clearForm();
        } else if (!Number.isInteger(Number(id))) {
            setMessage('Invalid ID');
            clearForm();
        } else {
            const response = await fetch(`/remark/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
            });

            if (!response.ok) {
                setMessage('Not found');
                clearForm();
            } else {
                const data = await response.json();
                if (data) {
                    //setFormValues(data);
                    setDisplayValues(data);
                    setMessage('Found');
                } else {
                    setMessage('Not found');
                    clearForm();
                }
            }
        }
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        const query = event.target.elements.remark_query_input.value.trim();

        const response = await fetch(`/remarks?query=${encodeURIComponent(query)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include cookies in the request
        });

        if (!response.ok) {
            setMessage('Not found');
            clearForm();
        } else {
            const data = await response.json();
            if (data) {
                setRecords(data);
                setMessage('Found records');
            } else {
                setMessage('Not found');
                clearForm();
            }
        }
    };

    const handleSubmit3 = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());

        // Convert form data to the required format
        const remarkData = {
            id: parseInt(data.id, 10),
            content: data.remark,
            reference: data.reference,
            pinned: data.pinned === 'true',
            skipped: data.skipped === 'true',
            reference_url: data.url.trim(),
        };

        // Make the POST request
        const response = await fetch('/remark', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include cookies in the request
            body: JSON.stringify(remarkData),
        });

        if (!response.ok) {
            console.error('Failed to submit impact data', await response.text());
            setMessage('Failed to save data - ' + response.statusText);
        } else {
            console.log('Reamrk submitted successfully');
            setMessage('Saved successfully!');
            const returnedData = await response.json();
            // Update the form values with the data from the response
            setDisplayValues(returnedData);
        }
    };

    const clearRecords = () => {
        setRecords([]);
    };

    return (
        <Layout>
            <Grid container id="grdMenu" className={classes.menuBand}>
                <Grid item xs={9}>
                    <Box sx={{ textAlign: 'left', paddingLeft: '8px' }}>
                        <Link href="/">Home</Link> | <Link href="/about">About</Link>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.rightHref}>
                        <Link component="button" onClick={() => changeFontSize(4)}>A</Link> |
                        <Link component="button" onClick={() => changeFontSize(-4)} style={{ marginRight: 20 }}>a</Link>
                    </Box>
                </Grid>
            </Grid>
            <hr className={classes.hrLine2} />
            <Grid>
                <Box pt={8} sx={{ overflow: 'auto', height: '100vh' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>Remarks</Typography>
                        <Box px={2}>
                            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                User:{' '}
                                <span style={{ fontWeight: 'bold', color: 'cadetBlue' }}>
                                    {loggedInUser}{' '}
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                    <Box border={1} borderColor="grey.300" p={2} m={2}>
                        <Grid container spacing={3}>
                            <Grid item xl={4}>
                                <Box component="form" onSubmit={handleSubmit1} display="flex" gap={2}>
                                    <TextField
                                        label="ID of Remark to fetch"
                                        name="remark_id_input"
                                        variant="outlined"
                                        value={remarkIdInput}
                                        onChange={(e) => setRemarkIdInput(e.target.value)}
                                    />
                                    <Button variant="contained" color="primary" sx={{ maxWidth: 'fit-content' }} type="submit">Submit</Button>
                                </Box>
                            </Grid>
                            <Grid item xl={5}>
                                <Box component="form" onSubmit={handleSubmit2} display="flex" gap={2}>
                                    <TextField
                                        label="Search Criteria"
                                        name="remark_query_input"
                                        variant="outlined"
                                        value={remarkQueryInput}
                                        onChange={(e) => setRemarkQueryInput(e.target.value)}
                                    />
                                    <Button variant="contained" color="primary" sx={{ maxWidth: 'fit-content' }} type="submit">Submit</Button>
                                </Box>
                            </Grid>
                            <Grid item xl={3}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={resetFormValues}
                                >
                                    Clear Form
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Box border={1} borderColor="grey.300" p={2} m={2}>
                                    <Typography variant="body1" color="error" sx={{ mt: 1, minHeight: '1em' }}>
                                        {message}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={6} ref={ref}>
                                <Box display="flex" flexDirection="column" gap={2}>
                                    <Typography variant="body1">Remark (System provide id, not for input) </Typography>
                                    <form onSubmit={handleSubmit3}>
                                        <TextField
                                            label="Reamrk ID"
                                            name="id"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={displayValues.id || formValues.id || ''}
                                            InputProps={{
                                                readOnly: true,
                                                style: { backgroundColor: paleGrayBackground },
                                            }}
                                        />
                                        <TextField
                                            label="Remark"
                                            name="remark"
                                            variant="outlined"
                                            fullWidth margin="normal"
                                            value={formValues.remark || displayValues.content || ''}
                                            onChange={event => setFormValues({ ...formValues, remark: event.target.value })}
                                        />
                                        <TextField
                                            label="Reference"
                                            name="reference"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={formValues.reference || (displayValues.reference && displayValues.reference.Valid ? displayValues.reference.String : '') || ''}
                                            onChange={event => setFormValues({ ...formValues, reference: event.target.value })}
                                        />
                                        <TextField
                                            label="Url"
                                            name="url"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={formValues.url || (displayValues.reference_url && displayValues.reference_url.Valid ? displayValues.reference_url.String : '') || ''}
                                            onChange={event => setFormValues({ ...formValues, url: event.target.value })}
                                        />
                                        <FormControl fullWidth margin="normal">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formValues.pinned || displayValues.pinned || false}
                                                        value={formValues.pinned || false}
                                                        onChange={(event) => {
                                                            const updatedPinnedValue = event.target.checked;
                                                            setFormValues(prevFormValues => ({
                                                                ...prevFormValues,
                                                                pinned: updatedPinnedValue
                                                            }));
                                                        }}
                                                        name="pinned"
                                                    />
                                                }
                                                label={<Typography variant="body1" style={{ fontWeight: 'normal', color: gray }}>Pinned</Typography>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formValues.skipped || displayValues.skipped || false}
                                                        value={formValues.skipped || false}
                                                        onChange={(event) => {
                                                            const updatedSkippedValue = event.target.checked;
                                                            setFormValues(prevFormValues => ({
                                                                ...prevFormValues,
                                                                skipped: updatedSkippedValue
                                                            }));
                                                        }}
                                                        name="skipped"
                                                    />
                                                }
                                                label={<Typography variant="body1" style={{ fontWeight: 'normal', color: gray }}>Skipped</Typography>}
                                            />
                                        </FormControl>
                                        <Button type="submit" variant="contained" color="primary" sx={{ maxWidth: 'fit-content', mt: 2 }}>Submit</Button>
                                    </form>
                                </Box>
                            </Grid>
                            <Grid item xl={6}>
                                <Box border={1} borderColor="grey.300" p={2} height={height} style={{ overflowX: 'auto' }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <h2>Search Output</h2>
                                        <Button variant="contained" color="secondary" onClick={clearRecords}>Clear Output</Button>
                                    </Box>
                                    <hr />
                                    {records.map((record, index) => (
                                        <div key={index}>
                                            <h3>Record {index + 1}</h3>
                                            {formatRecord(record)}
                                            <hr />
                                        </div>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Layout>
    );
};

export default Remark;