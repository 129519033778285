import React from 'react';
import { Box, Link, Grid } from '@mui/material';
import { useStyles } from './Styles';
import Layout from './Layout'; // Import Layout component
import { changeFontSize } from './ChangeFontSize';

function About() {
  const classes = useStyles();

  console.log('About page rendered');
  return (
    <Layout>
      <Grid container id="grdMenu" className={classes.menuBand}>
        <Grid item xs={9}>
          <Box sx={{ textAlign: 'left', paddingLeft: '8px' }}>
            <Link href="/">Home</Link> | <Link href="/login">Login</Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.rightHref}>
            <Link component="button" onClick={() => changeFontSize(4)}>A</Link> |
            <Link component="button" onClick={() => changeFontSize(-4)} style={{ marginRight: 20 }}>a</Link>
          </Box>
        </Grid>
      </Grid>
      <hr className={classes.hrLine2} />
      <Grid container className={classes.aboutText} id="grdMainBody">
        <div>
          <br />
          <p>21 Century Politics is a blog dedicated to publishing human-created political narration about political happenings worldwide. In an ever-deeply interconnected world, all humanity-encompassing perspectives can help us analyze and comprehend a multitude of political developments in meaningful ways.
            <br />
            <br />
            Our panel of contributors comes from different countries and backgrounds, bringing refreshing and original perspectives. Umesh Patil started the blog in December of 2004. It is hosted, funded, and managed by NeoSematix, Inc., a California-based corporation.
            <br />
            <br />
            All rights to the content published on this blog are with NeoSemantix, Inc., unless stated otherwise. For all inquiries, please contact Umesh at <em><strong>umesh@21centurypolitics.com</strong></em>.
          </p>
        </div>
      </Grid>
    </Layout>
  );
}

export default About;