import React from 'react';
import { Box, Link, Grid }  from '@mui/material';
import { useState } from 'react';
import { useStyles } from './Styles';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';

function LoginForm({ isLoggedIn, setIsLoggedIn, setLoggedInUser }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const classes = useStyles();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch('/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            console.log('Login successful');
            setIsLoggedIn(true); // Set isLoggedIn to true when login is successful
            setLoggedInUser(username); // Set the logged-in user
            navigate('/remark'); // Navigate to the impact-form page
        } else {
            console.log('Login failed');
        }
    };

    return (
        <Layout>
            <Grid container id="grdMenu" className={classes.menuBand}>
                <Grid item xs={9}>
                    <Box sx={{ textAlign: 'left', paddingLeft: '8px' }}>
                        <Link href="/">Home</Link> | <Link href="/about">About</Link>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.rightHref}>
                        <Link href="javascript:changeFontSize(4);">A</Link> | <Link href="javascript:changeFontSize(-4);" style={{ marginRight: 20 }}>a</Link>
                    </Box>
                </Grid>
            </Grid>
            <hr className={classes.hrLine2} />
            <Grid
                container
                className={classes.main}
                id="grdMainBody"
                style={{ minWidth: '320px', height: '50vh' }} // Ensure full viewport height
                direction="column" // Stack children vertically
                justifyContent="center" // Center vertically in the container
                alignItems="center" // Center horizontally in the container
            >
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}> {/* Center form contents */}
                    <div>
                        <label>
                            Username:
                            <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                        </label>
                    </div>
                    <div style={{ marginTop: '20px' }}> {/* Add some spacing between inputs */}
                        <label>
                            Password:
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </label>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </Grid>
        </Layout>
    );
}

export default LoginForm;