import React, { useState, useEffect } from 'react';
import { useStyles } from './Styles';
import { Typography, Box, Link, Grid, Button } from '@mui/material';
import Layout from './Layout';
import { getLocaleDate } from './DateUtil';
import { changeFontSize } from './ChangeFontSize';
import { Link as RouterLink } from 'react-router-dom';
import { stripFontSizeStyles } from './Util';

function parseArticleData(articleData) {
  let data;
  try {
    // Check if articleData is a string that needs parsing
    if (typeof articleData === 'string') {
      data = JSON.parse(articleData);
    } else if (typeof articleData === 'object') {
      // If articleData is already an object, use it directly
      data = articleData;
    } else {
      // If articleData is neither a string nor an object, log an error
      console.error("Invalid articleData type:", typeof articleData);
      return { articles: [], commentaries: [] };
    }
    const articles = data.articles;
    const commentaries = data.commentaries;
    return { articles, commentaries };
  } catch (error) {
    console.error("Error processing article data:", error);
    return { articles: [], commentaries: [] };
  }
}

function parseRemarkData(remarkData) {
  const textArray = remarkData.map(item => {
    return {
      text: item.content,
      url: item.reference_url.Valid ? item.reference_url.String : null
    };
  });
  return textArray;
}

const handleArticleClick = (article) => {
  localStorage.setItem('selectedArticle', JSON.stringify(article));
};

function HomePage({ articleData, remarkData }) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [text, setText] = useState('');
  const textArray = remarkData ? parseRemarkData(remarkData) : [];
  const [isPaused, setIsPaused] = useState(false);
  const { articles, commentaries } = parseArticleData(articleData);
  const [processedText, setProcessedText] = useState('');
  const [cleanedContent, setCleanedContent] = useState('');

  useEffect(() => {
    let timer;
    if (!isPaused) {
      if (textArray.length > 0 && text.length < textArray[currentIndex].text.length) {
        timer = setTimeout(() => {
          const newText = textArray[currentIndex].text.slice(0, text.length + 1);
          setText(newText);
          setProcessedText(processText(newText, textArray[currentIndex].url));
        }, 10); // Typing speed
      } else {
        timer = setTimeout(() => {
          setIsPaused(true);
          setTimeout(() => {
            setIsPaused(false);
            setText('');
            setProcessedText('');
            setCurrentIndex((currentIndex + 5) % textArray.length);
          }, 100); // Pause before moving to the next string
        }, 6000); // Pause after completing the string
      }
    }
    // Clean the content
    if (articles[0] && articles[0].content) {
      const cleaned = stripFontSizeStyles(articles[0].content);
      setCleanedContent(cleaned);
    }

    return () => clearTimeout(timer);
  }, [text, isPaused, currentIndex, textArray, articles]);

  const processText = (text, url) => {
    const refIndex = text.indexOf("[ref]");
    if (refIndex === -1) return text;

    const words = text.substring(0, refIndex).trim().split(" ");
    const lastWord = words.pop();
    const remainingText = words.join(" ");

    return (
      <>
        {remainingText} <a href={url} target="_blank" rel="noopener noreferrer">{lastWord}</a> {text.substring(refIndex + 5)}
      </>
    );
  };

  const processText2 = (index) => {
    const textItem = textArray[index];
    if (!textItem) return null;
    return processText(textItem.text, textItem.url);
  };


  return (
    <Layout>
      <Grid container id="grdMenu" className={classes.menuBand}>
        <Grid item xs={9}>
          <Box sx={{ textAlign: 'left', paddingLeft: '8px' }}>
            <Link href="/">Home</Link> | <Link href="/about">About</Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.rightHref}>
            <Link component="button" onClick={() => changeFontSize(4)}>A</Link> |
            <Link component="button" onClick={() => changeFontSize(-4)} style={{ marginRight: 20 }}>a</Link>
          </Box>
        </Grid>
      </Grid>
      <hr className={classes.hrLine2} />
      <Grid container className={classes.main} id="grdMainBody" style={{ minWidth: '320px' }}>
        {/* Main content */}
        <Grid item xs={12} sm={4} id="grdMainBodyLeftColumn" className={classes.mainColumn}>
          <Box className={classes.mainBoxBorder} sx={{ textAlign: 'left' }}>
            {/* Display the first article in its entirety - className={classes.articleTitle}*/}
            <h3>{articles[0].title}</h3>
            <Typography className={classes.authorSpan}>
              by {articles[0].authors}<br />{getLocaleDate(new Date(articles[0].published))}
            </Typography>
            {/* Render article content as HTML */}
            <div className={classes.middleColPreview} dangerouslySetInnerHTML={{ __html: cleanedContent }}></div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} id="grdMainBodyMiddleColumn" className={classes.mainColumn}>
          <Box className={classes.mainBoxBorder} sx={{ textAlign: 'left' }}>
            <h3>Recent Posts</h3>
            {articles.slice(1).map((article) => (
              <div key={article.id} className={classes.articleContainer}>
                <h3 className={classes.articleTitle}>
                  <Link
                    component={RouterLink}
                    to={`/article/${article.id}`}
                    onClick={() => handleArticleClick(article)}
                  >
                    {article.title}
                  </Link>
                  <span className={classes.authorSpan}> by {article.authors}</span>
                </h3>
                <span className={classes.authorSpan}>{getLocaleDate(new Date(article.published))}</span>
                <span className={classes.middleColPreview}>
                  {article.preview.slice(0, 250)}
                  {article.preview.length > 150 ? "..." : ""}
                </span>
              </div>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} id="grdMainBodyRightColumn" className={classes.mainColumn}>
          <Grid container direction='column'>
            <Box className={classes.commentary}>
              <h3>Commentary</h3>
              {commentaries
                .sort((a, b) => new Date(b.published) - new Date(a.published)) // Sort by date
                //.filter(commentary => commentary.title.startsWith("Commentary: ")) // Step 1: Filter articles
                .map((commentary) => (                                              // // Step 2: Remove prefix
                  <div key={commentary.id} className={classes.commentaryContainer}>
                    <h3 className={classes.articleTitle}>
                      <Link
                        component={RouterLink}
                        to={`/article/${commentary.id}`}
                        onClick={() => handleArticleClick(commentary)}
                      >
                        {commentary.title.replace(/^Commentary: /, '')}
                      </Link>
                        <span className={classes.authorSpan}> by {commentary.authors}</span>
                    </h3>
                    <span className={classes.authorSpan}>{getLocaleDate(new Date(commentary.published))}</span>&nbsp;
                    <br />
                    {/* <span className={classes.middleColPreview}>{commentary.preview}</span> */}
                  </div>
                ))}
            </Box>
            <Box className={classes.flexItem} style={{ textAlign: 'left', borderBottom: 'none' }}>
              <h3 className={classes.remarksHeading}>Remarks</h3>
              <Grid container className={classes.flexContainer} id="grdRemarks">
                <Box className={classes.flexItem}>
                  <div className={classes.remarkContainer}>
                    {processText2((currentIndex + 4) % textArray.length)}
                  </div>
                </Box>
                <Box className={classes.flexItem}>
                  <div className={classes.remarkContainer}>
                    {processText2((currentIndex + 3) % textArray.length)}
                  </div>
                </Box>
                <Box className={classes.flexItem}>
                  <div className={classes.remarkContainer}>
                    {processText2((currentIndex + 2) % textArray.length)}
                  </div>
                </Box>
                <Box className={classes.flexItem}>
                  <div className={classes.remarkContainer}>
                    {processText2((currentIndex + 1) % textArray.length)}
                  </div>
                </Box>
                <Box className={classes.flexItem} style={{ position: 'relative' }}>
                  <div className={classes.remarkContainer}>
                    {processedText}
                  </div>
                </Box>
              </Grid>
            </Box>
            <Box className={classes.flexItem} style={{ textAlign: 'right', borderBottom: 'none' }}>
              {/* <Button onClick={() => setIsPaused(!isPaused)}>{isPaused ? 'Resume' : 'Pause'}</Button> */}
              <Button onClick={() => setIsPaused(!isPaused)}>{isPaused ? '>>' : '||'}</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default HomePage;