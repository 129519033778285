// export const changeFontSize = (inc) => {
// 	// Element id is fixed here, but it can be passed as argument.
// 	var p = document.getElementById('grdMainBody');
// 	var size = parseInt(p.style.fontSize.replace("px", ""));
// 	if (isInvalidSize(size)) {
// 		size = getComputedFontSize(p);
// 	}
// 	size = size + inc + 'px';
// 	p.style.fontSize = size;
// 	console.log("New size: " + size);
// 	// store the value
// 	sessionStorage.setItem('fontSize', size);
// };

// ChangeFontSize.js
//function changeFontSize(delta) {
export const changeFontSize = (delta) => {
	const element = document.getElementById('grdMainBody');
	if (element) {
		const currentFontSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
		const newFontSize = parseFloat(currentFontSize) + delta;
		element.style.setProperty('font-size', `${newFontSize}px`, 'important');
		console.log("New size: " + newFontSize + 'px');
		// store the value
		sessionStorage.setItem('fontSize', `${newFontSize}px`);
	} else {
		console.error('Element not found');
	}
}


function isInvalidSize(size) {
	var invalid = false;
	if (!size || size == "" || size == 'NaN' || size == 'NaNpx') {
		invalid = true;
	}
	return invalid;
};

function getComputedFontSize(elem) {
	var elemCompStyle = window.getComputedStyle(elem, null);
	var szpx = window.getComputedStyle(elem, null).getPropertyValue("font-size");
	return parseInt(szpx.replace("px", ""));
};


function getDefaultFontSize(elem) {
	var size = sessionStorage.getItem('fontSize');
	if (isInvalidSize(size)) {
		// stored size is invalid
		// let us check if the element has any set
		var elemSize = elem.style.fontSize;
		if (isInvalidSize(elemSize)) {
			// element does not have any valid size
			// we need to use the computed size else
			size = getComputedFontSize(elem);
		} else {
			// we use the element size
			size = elemSize;
		}
	}
	// else we have a valid stored value, we return that
	return size;
};